


































































































































































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default defineComponent({
  name: 'UOttawaLab2SelfAssessment',
  components: {
    StembleLatex,
    CalculationInput,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        partnerNames: null,
        gradeA: null,
        gradeB: null,
        gradeC: null,
        gradeD: null,
        gradeE: null,
        gradeF: null,
        gradeG: null,
        gradeH: null,
        totalGrade: null,
      },
    };
  },
  // computed: {
  //   totalGrade() {
  //     return Object.entries()
  //       .filter(([key, value]) => key.startsWith('grade') && value !== null)
  //       .map(([_, value]) => value.toFloat())
  //       .reduce((total, grade) => total + grade, 0);
  //   },
  // },
});
